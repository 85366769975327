#contenedor_libro {
    width: 500px;
}

#imagen_principal {
    width: 550px;
    height: 550px;
}

@media (max-width: 550px) {
    #contenedor_libro {
        width: 100%
    }

    #imagen_principal {
        width: 100vw !important; 
        height: 550px;
    }
}