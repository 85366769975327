.snowfall-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    overflow: hidden;
    z-index: 1000;
  }
  
  .snowflake {
    position: absolute;
    top: -10px;
    width: 10px;
    height: 10px;
    background-color: #ffffff;
    border-radius: 50%;
    animation: fall linear infinite;
  }
  
  @keyframes fall {
    to {
      transform: translateY(100vh);
    }
  }
  