#barra_web{
    display: flex;
}

#barra_web a{
    text-decoration: none;
    color: white;
}

#barra_movil{
    display: none;
}

@media (max-width: 580px){
    #logo_web_movil{
        display: none;
    }
}

@media (max-width: 840px){
    #barra_web{
        display: none;
    }

    #barra_movil{
        display: flex;
    }

    #barra_movil a{
        text-decoration: none;
        color: white;
    }
}