/* Para dashboard*/
.imagenLibro {
  width: 170px;
  height: 170px;
}

#blur_background {
  position: relative;
  width: 100%;
  height: 100%;
}

#blur_background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(/images/pattern_background.jpg);
  background-repeat: repeat;
  filter: blur(6px);
  z-index: -1;
}

/*Para fullscreen*/
:fullscreen {
  object-fit: contain !important;
  height: auto !important;
  width: auto !important;
  max-height: 100% !important;
  max-width: 100% !important;
}

:-webkit-full-screen {
  object-fit: contain !important;
  height: auto !important;
  width: auto !important;
  max-height: 100% !important;
  max-width: 100% !important;
}

:-moz-full-screen {
  object-fit: contain !important;
  height: auto !important;
  width: auto !important;
  max-height: 100% !important;
  max-width: 100% !important;
}

:-ms-fullscreen {
  object-fit: contain !important;
  height: auto !important;
  width: auto !important;
  max-height: 100% !important;
  max-width: 100% !important;
}

@media (max-width: 850px) {
  .imagenLibro {
    width: 150px;
    height: 150px;
  }


}

@media (max-width: 450px) {
  .bannerAmazon {
    width: 283px;
    height: 54.98px;
  }
}